import axios from "axios";
import { Message } from "element-ui";
const service = axios.create({
  baseURL: "https://ojs.acad-pub.com/index.php/",
  // baseURL: "http://ceshi.acad-pub.com/index.php/",
  timeout: 10000,
});
service.interceptors.request.use(
  (config) => {
    const url = new URL(config.url, window.location.href);
    const queryParams = new URLSearchParams(url.search);
    if (queryParams.has("apiToken")) {
      const apiToken = queryParams.get("apiToken");
      if (!apiToken) {
        window.location.href = "/login";
        return Promise.reject(new Error("Missing API Token"));
      }
    }
    return config;
  },
  (error) => {
    console.error("Request error:", error);
    // Handling request errors to avoid page crashes caused by uncaught errors
    return Promise.reject(error); // Reject the error so it can be caught later
  }
);
service.interceptors.response.use(
  function (response) {
    const contentType = response.headers["content-type"];
    if (contentType && contentType.includes("application/pdf")) {
      return response;
    } else if (response.status == 200) {
      const res = response.data;
      if (res.code == 200 || res.code == 401) {
        return res;
      } else {
        Message({
          type: "error",
          message: res.message || "Request error",
        });
        return Promise.reject(new Error(res.message || "Request error"));
      }
    } else {
      return Promise.reject(new Error("Unexpected response status"));
    }
  },
  function (error) {
    if (error.code === "ECONNABORTED") {
      Message({
        type: "error",
        message: "Request timed out, please try again later",
      });
      //   console.error("Request timed out, please try again later");
      return retryRequest(error.config);
    }
    Message({
      type: "error",
      message: error.message || "Request error",
    });
    return Promise.reject(error);
  }
);
function retryRequest(config, attempt = 0, retries = 3) {
  return new Promise((resolve, reject) => {
    attempt++;
    service(config)
      .then(resolve)
      .catch((error) => {
        if (attempt <= retries) {
          retryRequest(config, attempt, retries).then(resolve).catch(reject);
        } else {
          Message({
            type: "error",
            message: error.message || "Request failed after multiple attempts",
          });
          //   console.error(
          //     error.message || "Request failed after multiple attempts"
          //   );
          reject(error);
        }
      });
  });
}
export default service;
